<template>
    <section class="mb-10" :class="{'mobile-layout' :is_screen_small, 'impact-layout' : !is_screen_small }">   
            <v-layout justify-space-around justify-center  class="impact-parallax">
                <v-flex xs9 sm9 offset-sm2 class="mt-10">
                        <v-card flat tile color="" width="90%" class="transparent main-content-products text-center" justify-center>
                            <v-card-title primary-title class="justify-center">
                                    <h3 class="zamu-heading transact-heading about-heading">Company Conduct</h3><br />
                                    <v-card tile flat :color="`${zamuGrey}`" height="1" class="justify-center mt-5 transact-underline">
                                        <v-card-text class="transact-divider divider-purple"></v-card-text>
                                        <v-card-text class="transact-divider divider-white"> </v-card-text>
                                    </v-card>
                            </v-card-title>
                            <v-card-text align="center" justify="center">
                                <p class="product-text">
                                    We mirror and strictly enforce the Anti-Money Laundering (AML) and counter-terrorism financing measures championed by the Central Bank of Kenya (CBK) and the regulators in other markets we operate in as the benchmark in all our activities.
                                </p>
                            </v-card-text>
                            <v-row>
                                    <v-col cols="12"  lg="4" md="4" sm="6" xs="12" class="" v-for="(section, index) in sectionInfo" :key="index">
                                        <v-card tile flat :class="`${section.class}`" dark :color="`${section.color}`" min-height="255">
                                            <v-card-text class="" >
                                                <v-row align="center">
                                                    <v-col  lg="12" md="12" sm="12" xs="12"  cols="12" align="center" justify="center">
                                                        <v-icon size="28" :color="`${zamuBlack}`" :class="`${section.class}-icon`">{{ section.icon }}</v-icon>

                                                        <v-divider :color="`${zamuBlack}`" class="icon-divider top-divider" :class="`${section.class}-hr`"></v-divider>
                                                        <v-divider :color="`${zamuBlack}`" class="icon-divider" :class="`${section.class}-hr`"></v-divider>

                                                    </v-col>
                                                    <v-col  lg="12" md="12" sm="12" xs="12"  cols="12" align="center" justify="center" :class="`${section.class}`">
                                                          <h3 class="impact-header ">{{ section.title }}</h3>
                                                        <p class="mt-4 impact-text-section">{{ section.text }}</p>

                                                      
                                                    </v-col>

                                                </v-row>
                                            </v-card-text>                                    
                                        </v-card>
                                    </v-col>
                            </v-row>
                        </v-card>
                </v-flex>
            </v-layout>
        </section>
</template>

<script>
import colorMixin from '@/mixins/colorMixin';
import coreMixin from '@/mixins/coreMixin';

export default {
        mixins: [colorMixin, coreMixin],
          computed: {
            sectionInfo () {
                const data = [
                    {
                        image: 'bulb.png',
                        title: 'Always On',
                        text: 'We have unrivaled and unmatched customer support',
                        color: '#ffffff',
                        class: 'family-impact',
                        icon: 'mdi-lightbulb-outline',
                    },
                    {
                        image: 'white_pie.png',
                        title: 'Dynamic Team',
                        text: `We deliberately leverage on a young and dynamic team of professionals with unique skill sets to power our products 
and services to enhance and sustain quality delivery.`,
                        color: '#43227c',
                        class: 'business-impact',
                        icon: 'mdi-chart-pie',
                    },
                    {
                        image: 'umbrella.png',
                        title: 'Policies',
                        text: 'We have established an IT policy that governs the security of all our networks, systems and work environments.',
                        color: '#ffffff',
                        class: 'enterpreneurs-impact',
                        icon: 'mdi-umbrella-outline',
                    }
                ];
                return data;

            },

      },

}
</script>

<style>
.impact-parallax {
      background: #d8960ac7;
}
.impact-layout {
    background-image: url('https://pilongtermbackups.s3.amazonaws.com/Zamupay/images/happyLady.png') !important;
    background-position: right center !important;
}
.header-divider {
  width: 24%;
}
.mobile-layout {
  background: #d8960a;
}

</style>